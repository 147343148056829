
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonButtons, IonButton, IonSpinner, IonIcon, modalController, menuController } from "@ionic/vue";
import { ref } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { refresh, menu } from "ionicons/icons";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiMagazzino from "@/services/magazzino";
import MovimentoDetail from "@/components/magazzino/MovimentoDetail.vue";
import ModalNuovoMovimento from "@/components/magazzino/Test.vue";
//import ModalNuovoMovimento from "@/components/magazzino/ModalNuovoMovimento.vue";

export default {
    name: "Movimenti",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonButtons,
        IonButton,
        IonSpinner,
        IonIcon,
    },
    setup() {
        const user = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`));
        const userId = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_id;
        const loading = ref(false);

        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * Get all automezzi from the server (refresh icon)
         */
        const movimenti = ref([]);
        async function loadMovimenti() {
            loading.value = true;
            try {
                const res = await apiMagazzino.getMovimenti(userId);
                if (res.status === 200 && res.data.status === 0) {
                    movimenti.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta dei movimenti", "toast_danger");
                }
                //console.log(prenotazioni.value);
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta dei movimenti", "toast_danger");
            }
            loading.value = false;
        }
        loadMovimenti();

        /**
         * Open prenotazione automezzo detail page
         */
        async function openDetailModal(movimento) {
            const modal = await modalController.create({
                component: MovimentoDetail,
                componentProps: {
                    data: movimento,
                },
            });
            /* modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    //console.log(detail);
                    //Cerco prenotazione appena modificata (detail non è undefined se ho modificato prenotazione, ho chiuso il form, aggiornato il dettaglio per vedere i dati modificati e poi chiuso anche il dettaglio)
                    const editedPrenotazione = detail.data;
                    const editedIndex = prenotazioni.value.findIndex(
                        (prenotazione) => prenotazione.prenotazioni_automezzi_id == editedPrenotazione.prenotazioni_automezzi_id
                    );
                    if (editedIndex && editedPrenotazione) {
                        prenotazioni.value[editedIndex] = editedPrenotazione;
                    }
                }
            }); */
            return modal.present();
        }

        /**
         * Open modal to create new prenotazione
         */
        async function createMovimento() {
            const modal = await modalController.create({
                component: ModalNuovoMovimento,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    movimenti.value = [detail.data, ...movimenti.value];
                    openToast("Nuovo movimento creato con success", "toast_success");
                }
            });
            return modal.present();
        }

        return {
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            //New field
            refresh,
            loading,
            movimenti,
            openDetailModal,
            createMovimento,
            menu,
            openMenu,
            loadMovimenti,
        };
    },
};
